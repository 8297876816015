import {Card} from "react-bootstrap"; // Include Bootstrap

const BuildingErrorCard = () => {
    return (
        <Card className="black-card py-3 ">
            <Card.Body className="d-flex flex-column font-weight-light justify-content-center text-center text-white p-0">
                <h3 className="text-2xl font-weight-bold tracking-wide">Oops!</h3>
                <p className="font-weight-bold">Something went wrong. Please try again later.</p>
                <p className="text-sm">If the problem persists, contact support or try refreshing the page.</p>
            </Card.Body>
        </Card>
    );
}

export default BuildingErrorCard;
