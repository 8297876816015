import moment from "moment/moment";
import React, {useRef, useState} from "react";
import PropTypes from 'prop-types';
import constants from "../../../../../../component/constants";

const BuildingLedgerExportPdf = ({ buildingId, startDate, endDate }) => {
    // Access application URL from constants
    const { APP_URL } = constants;

    // States for handling export functionality (errors and loading state)
    const [exportError, setExportError] = useState();
    const [isExportLoading, setIsExportLoading] = useState(false);

    // Ref to manage fetch request aborts
    const abortControllerForExport = useRef(null);

    // Function to export building ledger data as a PDF
    const exportBuildingLedgers = async () => {
        // Abort any previous fetch request
        abortControllerForExport.current?.abort();
        abortControllerForExport.current = new AbortController();

        // Set loading state
        setIsExportLoading(true);

        // Construct the URL for fetching building data with query parameters
        const formattedLedgerStartDate = moment(startDate).format('YYYY-MM-DD');
        const formattedLedgerEndDate = moment(endDate).format('YYYY-MM-DD');
        const buildingLedgersEndpoint = APP_URL + `buildings/${buildingId}/export?startDate=${formattedLedgerStartDate}&endDate=${formattedLedgerEndDate}`;

        try {
            // Fetch building ledger data for export from the API
            const response = await fetch(buildingLedgersEndpoint, {
                signal: abortControllerForExport.current?.signal, // Attach abort signal
            });

            // Convert the response to a Blob (PDF file)
            const result = await response.blob();

            // Create a temporary link element to trigger the download
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(result);  // Create a URL for the PDF blob
            link.download = 'building-financial-transactions.pdf';  // Set the default file name
            document.body.appendChild(link);  // Append the link to the document
            link.click();  // Programmatically trigger the download

            // Clean up the temporary URL and remove the link element
            window.URL.revokeObjectURL(link.href);
            link.remove();
        } catch (e) {
            // Handle the abort case separately
            if (e.name === 'AbortError') {
                console.log('Export Building Ledgers PDF Aborted');
                return;
            }

            // Set error state if the export fetch fails
            setExportError(e);
        } finally {
            // Reset loading state after export is complete or fails
            setIsExportLoading(false);
        }
    };

    return (
        <>
            <button onClick={exportBuildingLedgers}
                    className="border d-flex flex-column align-items-center justify-content-center text-white rounded-lg border-4 border-black border-opacity-10 p-2 w-100">
                {isExportLoading ? (
                    <>
                        <i className="fa fa-spinner fa-spin fa-2x fa-fw"></i>
                        <span className="d-block text-sm text-uppercase font-weight-semibold mt-2">PDF Exporting...</span>
                    </>
                ) : (
                    <>
                        <i className="fa fa-file-pdf-o fa-2x text-white" aria-hidden="true"></i>
                        <span className="d-block text-sm text-uppercase font-weight-semibold mt-2">PDF</span>
                    </>
                )}
            </button>
        </>
    )
}

// Define the prop types for the component
BuildingLedgerExportPdf.propTypes = {
    buildingId: PropTypes.string.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
};

export default BuildingLedgerExportPdf;
