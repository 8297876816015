import { useState, useEffect } from "react";

// Custom hook to debounce a value (delay updates)
export const useDebounce = (value, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = useState(value); // Store the debounced value

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedValue(value); // Update debounced value after the delay
        }, delay);

        return () => {
            clearTimeout(timeout); // Clear timeout if value or delay changes
        };
    }, [value, delay]); // Run effect when value or delay changes

    return debouncedValue; // Return the debounced value
}
