import React, { Component } from "react";
import moment from 'moment'
import constants from "../constants";
import { store } from 'react-notifications-component';

export default class Index extends Component
{
    /**
     * add suffix to thte number
     *
     * @param {int} num
     * @returns {string}
    */
    getNumberWithOrdinal(num)
    {
        var s = ["th", "st", "nd", "rd"],
            v = num % 100;
        return num + (s[(v - 20) % 10] || s[v] || s[0]);
    }
    /**
     * get lease status
     * 
     * @param {object} props 
     * @returns {string}
    */
    leaseStatus( props )
    {
        const { leaseDetails, constants }  = props
        if( leaseDetails ){
            if( leaseDetails.kill_tenancy  == 0 && moment(leaseDetails.tenancy_end_date).format('x') > moment().format('x') )
                return constants.LEASED
            else if( leaseDetails.kill_tenancy  == 0 && leaseDetails.tenancy_end_date === null )
                return constants.LEASED
            else if( leaseDetails.kill_tenancy  == 1 && moment(leaseDetails.tenancy_end_date).format('x') < moment().format('x') )
                return constants.VACANT
            else if( leaseDetails.kill_tenancy  == 1 && leaseDetails.tenancy_end_date === null )
                return constants.LEASED
            else if( leaseDetails.kill_tenancy  == 1 && moment(leaseDetails.tenancy_end_date).format('x') > moment().format('x') )
                return constants.LEASED
            else
                return constants.VACANT
        }
        return constants.VACANT
    }

    /**
     * get user auth information from local storage
     * 
     * @param {*} NA
     * @returns {*}
     */
    getAuthUser()
    {
        return JSON.parse(localStorage.getItem('userAccessInfo'))
    }
    
    /** explode data
     *  
     * @param {string} path 
     * @param {string} seperater 
     * @returns 
     */
    explode(path)
    {
        try {
            var data  = path.split('/')
            return data[1]

        } catch (error) {
        }
    }

    /**
     * get lease status
     * 
     * @param {object} props 
     * @returns {string}
    */
    buildingLeaseStatus( props,  property )
     {
        const { lease }     = property
        let leaseData       =  lease

         if( leaseData ){
 
            if( property?.show_shine)
                 return 'for_lease'

             if( moment(leaseData.tenancy_end_date).format('x') > moment().format('x') )
                 return 'leased'
 
             if( leaseData.tenancy_end_date === null )
                 return 'leased'
 
             if( leaseData.kill_tenancy  == 1 && moment(leaseData.tenancy_end_date).format('x') < moment().format('x') )
                 return 'for_lease'
 
             if( leaseData.kill_tenancy  == 1 && leaseData.tenancy_end_date === null )
                 return 'leased'
         }
         return 'for_lease'
    }
    /**
     * get leased only status
     * 
     * @param {object} propertyList 
     * @returns {object}
    */
    findLeaseOnly(propertyList){
        return propertyList.filter( leaseData => ( leaseData.lease && 
            (moment(leaseData.lease.tenancy_end_date).format('x') > moment().format('x')  || leaseData.lease.tenancy_end_date === null)
        ))
    }
    /**
     * convert currency sign with math sign
     *  
     * @param {float} value 
     * @param {boolean} isSign 
     * @returns {string}
     */
    currencySign(value, isSign)
    {   
        let curformat = value

        try {

            value = Math.round(value * 100) / 100;
            
            if(  value  == -0 ){
                curformat = "0.00"
            }
            else if( parseFloat(value)  == 0 ){
                curformat = new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2}).format(value)
            }
            else if( Math.sign(value)  == -1 ){
                curformat   = new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2}).format(value)
            }
            else{
                if( isSign ){
                    curformat   = new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2}).format(value)
                    curformat   = '+'+curformat
                }
                else{
                    curformat   = new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2}).format(value)
                    curformat   = curformat
                }
            }
        } catch (error) {
            curformat = value;
        }
        return curformat
    }

    /** folio view building calculation */
    
    /**
     * get building status
     * 
     * @param {int} num 
     * @returns {string}
    */
     buildingStatus(proeprties) 
     {
             console.log('test', proeprties);
     }

    /** property fees calculate */


    /**
     * total fees
     * 
     * @param {object} option 
     * @param {object} constants 
     * @returns 
     */
    fees(option, constants)
     {
     const { ledgers } = option
     let netBalance    = 0
     
     try {
 
         ledgers.forEach(element => {
         
         if(constants.BUILDING_FEES_ENTRY.includes((element.description).toLowerCase()))
             netBalance  += parseFloat(element.amount)
         });
 
         /** total fees by the end of the calendar */
         this._fee  += netBalance
 
         return netBalance
     } catch (error) {}
 
     return netBalance
    }

    /**
     * get lease status
     * 
     * @param {object} props 
     * @returns {string}
    */
    findBuildingStatus( property )
    {
        if( property ){
            if( property?.lease?.kill_tenancy  == 0 && moment(property?.lease?.tenancy_end_date).format('x') > moment().format('x') )
                return true
            else if( property?.lease?.kill_tenancy  == 0 && property?.lease?.tenancy_end_date === null )
                return true
            else if( property?.lease?.kill_tenancy  == 1 && moment(property?.lease?.tenancy_end_date).format('x') < moment().format('x') )
                return false
            else if( property?.lease?.kill_tenancy  == 1 && property?.lease?.tenancy_end_date === null )
                return true
            else if( property?.lease?.kill_tenancy  == 1 && moment(property?.lease?.tenancy_end_date).format('x') > moment().format('x') )
                return true
            else
                return false
        }
        return false
    }

    /**
     * get building status
     * 
     * @param {object} props 
     * @returns {string}
    */
    buildingStatus( building )
    {   
        let leased          = 0
        let forLease        = 0
        let response        = 0    

        building?.property?.map( (property) => {

            if( this.findBuildingStatus( property ))
                leased  += 1
            else
                forLease    += 1
        }, this)

        if( leased > 0 && forLease > 0 )
            response    = ((leased * 100) / forLease) 

        else if( leased > 0 && forLease == 0 )    
            response    = ((leased * 100) / leased)

        return response
    }

    /**
     * net past due fr last month
     * 
     * @param {object} option 
     * @param {object} constants 
     * @returns 
    */
    held(option, constants)
    {
        const { ledger_balance } = option
        let totalDebit  =  0
        let totalCredit =  0
        try {
            ledger_balance?.forEach(element => {
            
            if( element.txn_type.toLowerCase() == constants.TYPES.DEBIT )
                totalDebit  += parseFloat(element.amount)
            else
                totalCredit  += parseFloat(element.amount)
            });

            return { debit: totalDebit, credit: totalCredit }
        } catch (error) {}

        return { debit: totalDebit, credit: totalCredit }
    }
    /**
     * total transfer to landloard
     * 
     * @param {object} option 
     * @param {object} constants 
     * @returns 
    */
    banked(option)
    {
        const { total_rent } = option
        let netBalance    = 0
        try {

            total_rent.forEach(element => {
            
                netBalance  += parseFloat(element.amount)
            });

            return netBalance
        } catch (error) { }

        return netBalance
    }

    /**
     * total
     * @param {object} folioData 
     */
    leasedPercentage(folioData)
    {
        let percentage  = 0
        try {
            
            const { leasedProperty } = folioData

            let properties  = folioData?.propertyList?.length
            percentage      = Math.round(( parseFloat( leasedProperty?.length ) *100) / properties )


        } catch (error) {
            
        }
        return percentage ? percentage : 0
    }

    getReadMoreText(text, len)
    {
        try {
            
            let textLen  = text?.length
            if( textLen > parseInt(len) )
            {
                return text.substring(0, len) + '...   '
            }

        } catch (error) {
            console.log(error, 'error');
        }
        return false
    }

    // Function to format an amount as a currency
    formatCurrency(amount, currency = 'CAD', locale = 'en-CA') {
        if (Math.abs(amount) < 1e-12) {  // Handle tiny numbers close to zero
            amount = 0;  // Set to 0 if effectively zero
        }

        // Format the amount using the given locale and currency
        return new Intl.NumberFormat(locale, {
            style: 'currency',    // Format as currency
            currency: currency,   // Use the specified currency
        }).format(amount); // Return the formatted amount
    }

    formatDate(date) {
        return moment(date).format('MM/DD/YY');
    }

    notify(title, message, status = 'default') {
        store.addNotification({
            title: title,
            message: message,
            type: status,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }
}
