import PropTypes from 'prop-types';
import {CSVLink} from "react-csv";

const BuildingLedgerExportCsv = ({ data }) => {
    // Sort data by created_at date (ascending order)
    const sortedData = [...data].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

    // Map the sorted data to the format expected for the CSV
    const csvData = sortedData.map((item) => ({
        date: item.created_at,
        suite: item.property.unit, // The 'suite' comes from property.unit
        description: item.description,
        amount: item.amount,
        balance: item.balance
    }));

    // CSV headers
    const headers = [
        { label: 'Date', key: 'date' },
        { label: 'Suite', key: 'suite' },
        { label: 'Description', key: 'description' },
        { label: 'Amount', key: 'amount' },
        { label: 'Balance', key: 'balance' }
    ];

    return (
        <>
            <CSVLink
                data={csvData}
                headers={headers}
                filename={"building-financial-transactions.csv"}
                className="border d-flex flex-column align-items-center justify-content-center text-white rounded-lg border-4 border-black border-opacity-10 p-2 w-100"
            >
                <i className="fa fa-file-text-o fa-2x text-white" aria-hidden="true"></i>
                <span className="d-block text-sm text-uppercase font-weight-semibold mt-2">CSV</span>
            </CSVLink>
        </>
    )
}

// Define the prop types for the component
BuildingLedgerExportCsv.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            created_at: PropTypes.string.isRequired,
            property: PropTypes.object.isRequired,
            description: PropTypes.string.isRequired,
            amount: PropTypes.number.isRequired,
            balance: PropTypes.number.isRequired,
        })
    ).isRequired,
};

export default BuildingLedgerExportCsv;
