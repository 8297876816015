import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Chart from 'react-apexcharts';

const Showings = ({ data }) => {
    // Check if data is defined
    if (!data) {
        return (
            <Card className="black-card py-3">
                <Card.Body
                    className="d-flex flex-column font-weight-light justify-content-center text-center text-white p-0">
                    <h3 className="text-lg font-weight-light text-uppercase tracking-wide mb-1">Weekly Showings</h3>
                    <div className="px-2 block text-sm font-weight-light leading-snug tracking-wide">Chart generation failed: Insufficient Data</div>
                </Card.Body>
            </Card>
        );
    }

    const generateChartOptions = () => {
        const showings = data.total_showings;
        const applications = data.total_applications;
        const leased = data.total_leased;

        let series = [showings, applications, leased];

        // Set series to an empty array if all values are zero
        series = series.every(value => value === 0) ? [] : series;

        return {
            series: series,
            options: {
                chart: {
                    type: 'donut',
                    toolbar: {
                        show: false,
                    },
                    events: {
                        mounted: function() {
                            // Remove all <title> elements inside the ApexCharts SVG
                            const svgTitles = document.querySelectorAll("#showings-chart .apexcharts-svg title");
                            svgTitles.forEach(title => title.remove());
                        },
                        updated: function() {
                            // Remove all <title> elements inside the ApexCharts SVG
                            const svgTitles = document.querySelectorAll("#showings-chart .apexcharts-svg title");
                            svgTitles.forEach(title => title.remove());
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                labels: ['Showings', 'Applied', 'Leased'],
                colors: ['#B38840', '#000000', '#FFFFFF'],
                title: {
                    text: 'WEEKLY SHOWINGS',
                    align: 'center',
                    style: {
                        fontSize: '18px',
                        fontWeight: 'light',
                        color: '#FFFFFF',
                        fontFamily: 'Inter, sans-serif',
                    },
                },
                stroke: {
                    show: false,
                    width: 0,
                },
                tooltip: {
                    theme: 'dark',
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Inter, sans-serif',
                        color: '#000000'
                    },
                    fillSeriesColor: false,
                    marker: {
                        show: true,
                    },
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                    labels: {
                        colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF'],
                    },
                    fontSize: '12px',
                    fontFamily: 'Inter, sans-serif',
                    formatter: function(value, opts) {
                        if (opts.w.globals.series[opts.seriesIndex] !== undefined) {
                            return value + " - " + opts.w.globals.series[opts.seriesIndex];
                        }
                        return value;
                    }
                },
                noData: {
                    text: 'No Data Available',
                    align: 'center',
                    verticalAlign: 'middle',
                    style: {
                        fontSize: '16px',
                        fontFamily: 'Inter, sans-serif',
                        color: '#FFFFFF',
                    },
                },
            },
        }
    };

    // Get the chart options for this building
    const chartOptions = generateChartOptions();

    return (
        <Card className="black-card">
            <Card.Body className="text-white px-2 pt-3 pb-4">
                <Chart options={chartOptions.options} series={chartOptions.series} type="donut" height={chartOptions.series.length ? 350 : 320} className="d-block m-auto" id="showings-chart" />
            </Card.Body>
        </Card>
    );
}

// Define the prop types for the component
Showings.propTypes = {
    data: PropTypes.shape({
        total_showings: PropTypes.number.isRequired,
        total_applications: PropTypes.number.isRequired,
        total_leased: PropTypes.number.isRequired,
    }),
};

export default Showings
