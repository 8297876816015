import React, {useState} from "react";
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import BuildingLedgerExportPdf from "./BuildingLedgerExportPdf";
import BuildingLedgerExportCsv from "./BuildingLedgerExportCsv";

const BuildingLedgerExport = ({ buildingId, startDate, endDate, data }) => {
    // State to control modal visibility
    const [isExportModalVisible, setIsExportModalVisible] = useState(false);

    // Function to show the modal
    const showExportModal = () => setIsExportModalVisible(true);

    // Function to hide the modal
    const hideExportModal = () => setIsExportModalVisible(false);

    return (
        <>
            <button onClick={showExportModal}
                    className="bg_black_opacity d-flex flex-column align-items-center justify-content-center text-white rounded-lg border-4 border-black border-opacity-10 p-2 w-100">
                <i className="fa fa-file-o fa-2x text-white" aria-hidden="true"></i>
                <span className="d-block text-sm text-uppercase font-weight-semibold mt-2">Export</span>
            </button>

            <Modal show={isExportModalVisible} onHide={hideExportModal} size="md" className="modal-dark">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 className="text-white text-lg font-weight-light tracking-wide mb-0">Building Financial Transactions Report</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <BuildingLedgerExportPdf buildingId={buildingId} startDate={startDate} endDate={endDate} />
                        </div>
                        <div className="col-md-6">
                            <BuildingLedgerExportCsv data={data} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

// Define the prop types for the component
BuildingLedgerExport.propTypes = {
    buildingId: PropTypes.string.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    data: PropTypes.array.isRequired,
};

export default BuildingLedgerExport;
