import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import BootstrapTable from "react-bootstrap-table-next";
import constants from "../../../../../../component/constants";

const BuildingLedgerTable = ({ data }) => {
    // Get helper function from Redux store
    const { helper } = useSelector((state) => ({
        helper: state.dataPack.helper,
    }));

    const columns = [
        {
            dataField: 'created_at',
            text: 'Date',
            sort: true,
            formatter: (cell) => helper.formatDate(cell),
            classes: 'text-center text-xs',
        },
        {
            dataField: 'property.unit',
            text: 'Suite',
            sort: true,
            classes: 'text-center text-sm',
        },
        {
            dataField: 'description',
            text: 'Description',
            sort: true,
            classes: 'text-xs',
        },
        {
            dataField: 'amount',
            text: 'Amount',
            sort: true,
            classes: (cell, row) => `text-right text-xs font-semibold ${row.type === 'debit' ? 'text-red-500' : 'text-black'}`,
            formatter: (cell) => helper.formatCurrency(cell)
        },
        {
            dataField: 'balance',
            text: 'Balance',
            sort: true,
            classes: 'text-right text-xs font-semibold',
            formatter: (cell) => helper.formatCurrency(cell)
        },
        {
            dataField: '',
            text: '',
            isDummyField: true,
            formatter: (cell, row) => (
                row.invoice?.path ? (
                    <a href={`${constants?.MEDIAURL + row.invoice.path + row.invoice.name}`} className="badge badge-dark d-block py-1" title="Download File Attachment" target="_blank" download>
                        <i className="fa fa-file" aria-hidden="true"></i>
                    </a>
                ) : null
            ),
            classes: 'text-center text-sm',
        },
    ];

    // Define the default sorting (amount column in descending order)
    const defaultSorted = [{
        dataField: 'created_at', // default sort column
        order: 'asc' // 'asc' for ascending, 'desc' for descending
    }];

    return (
        <BootstrapTable
            wrapperClasses='building-ledger-table-container scrollable-table-container'
            classes='scrollable-table table-divider'
            bootstrap4
            keyField='id'
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            noDataIndication='No Financial Records Found'
        />
    );
};

// Define the prop types for the component
BuildingLedgerTable.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            created_at: PropTypes.string.isRequired,
            property: PropTypes.object.isRequired,
            description: PropTypes.string.isRequired,
            amount: PropTypes.number.isRequired,
            balance: PropTypes.number.isRequired,
        })
    ).isRequired,
};

export default BuildingLedgerTable;
