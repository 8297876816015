import {Button, Form} from "react-bootstrap";
import React from "react";

const Note = (props) => {
    const handleNoteChange = (e) => {
        props.update('note', e.target.value);
    };

    return (
        <div className="container-fluid">
            <Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="note" className="text-light">Please share anything that will help prepare for our meeting.</Form.Label>
                    <Form.Control
                        onChange={handleNoteChange}
                        as="textarea"
                        rows={5}
                        placeholder="Enter your comments or inquiry"
                        name="note"
                        minLength="25"
                        maxLength="160"
                        required
                    />
                </Form.Group>
            </Form.Group>

            <div className="row mt-3">
                <div className="col-md-6">
                    <button className='btn btn-secondary btn-block' onClick={props.previousStep}>Back</button>
                </div>
                <div className="col-md-6">
                    {/* Submit button to send the form */}
                    <Button onClick={props.submit} variant="primary" type="submit" className="w-100">
                        Submit
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Note;
