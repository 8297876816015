import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import { useSelector } from "react-redux";

const RentalIncome = ({ data }) => {
    // Get helper function from Redux store
    const { helper } = useSelector((state) => ({
        helper: state.dataPack.helper,
    }));

    // Check if data is defined
    if (!data) {
        return (
            <Card className="black-card py-3">
                <Card.Body
                    className="d-flex flex-column font-weight-light justify-content-center text-center text-white p-0">
                    <h3 className="text-lg font-weight-light text-uppercase tracking-wide mb-1">Rental Income</h3>
                    <div className="px-2 block text-sm font-weight-light leading-snug tracking-wide">Chart generation failed: Insufficient Data</div>
                </Card.Body>
            </Card>
        );
    }

    // Create chart options based on building data
    const generateChartOptions = () => {
        return {
            series: [data.income_target_percentage], // Set the percentage as the chart data
            options: {
                chart: {
                    type: 'radialBar', // Radial bar chart
                    offsetY: 0,
                    sparkline: {
                        enabled: true // Hide chart axes
                    },
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: '#ffffff', // White background for the bar track
                            strokeWidth: '97%',
                            margin: 5,
                        },
                        dataLabels: {
                            name: {
                                show: false, // Hide the name label
                            },
                            value: {
                                show: true, // Hide the value label
                                color: '#ffffff',
                                offsetY: -3,
                                fontSize: '1.5rem',
                                formatter: function (val) {
                                    return `${Math.round(val)}%`; // Format value as a percentage
                                },
                            },
                        },
                    },
                },
                fill: {
                    type: 'solid',
                    colors: '#b38840', // Set the color of the bar
                },
                tooltip: {
                    enabled: true,
                    theme: 'dark',
                    y: {
                        formatter: function (val) {
                            return `Rental Income at ${Math.round(val)}% of Target Income`; // Tooltip text
                        },
                        title: {
                            formatter: function () {
                                return '';
                            }
                        }
                    },
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Inter, sans-serif',
                    },
                },
            },
        }
    }

    // Get the chart options for this building
    const chartOptions = generateChartOptions();

    return (
        <Card className="black-card py-3">
            <Card.Body className="d-flex flex-column font-weight-light justify-content-center text-center text-white p-0">
                <h3 className="text-lg font-weight-light text-uppercase tracking-wide mb-1">Rental Income</h3>
                <span className="block text-sm text-uppercase font-weight-semibold leading-snug tracking-wide">Target {helper.formatCurrency(data.target_revenue)}</span>
                <div className="position-relative mx-n3 mt-n3 mb-2 text-center">
                    {/* Render the chart */}
                    <Chart options={chartOptions.options} series={chartOptions.series} type="radialBar" className="d-inline-block m-auto" />
                </div>
                <span className={`block text-2xl font-weight-light leading-snug tracking-wide ${data.income_target_percentage >= 100 ? 'text-green' : ''}`}>
                {helper.formatCurrency(data.total_due_rent)}
            </span>
            </Card.Body>
        </Card>
    );
}

// Define the prop types for the component
RentalIncome.propTypes = {
    data: PropTypes.shape({
        total_target_rent: PropTypes.number.isRequired,
        total_due_rent: PropTypes.number.isRequired,
        income_target_percentage: PropTypes.number.isRequired,
    }),
};

export default RentalIncome
