import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Chart from 'react-apexcharts';

const ArAging = ({ data }) => {
    // Check if data is defined
    if (!data) {
        return (
            <Card className="black-card py-3">
                <Card.Body
                    className="d-flex flex-column font-weight-light justify-content-center text-center text-white p-0">
                    <h3 className="text-lg font-weight-light text-uppercase tracking-wide mb-1">A/R Aging</h3>
                    <div className="px-2 block text-sm font-weight-light leading-snug tracking-wide">Chart generation failed: Insufficient Data</div>
                </Card.Body>
            </Card>
        );
    }

    const generateChartOptions = () => {
        // Define the aging categories for the Y-axis
        const categories = ['1 - 30 DAYS', '31 - 60 DAYS', '61 - 90 DAYS', '91 - 120 DAYS', '121+ DAYS'];

        // Extract the overall totals (rent + bill) for each aging category
        const totalData = [
            data['1_30_days'].total,
            data['31_60_days'].total,
            data['61_90_days'].total,
            data['91_120_days'].total,
            data['121_plus_days'].total,
        ];

        return {
            series: [
                {
                    name: 'Total A/R',
                    data: totalData, // Use the overall total for each aging category
                    color: '#B38840', // Customize color
                },
            ],
            options: {
                chart: {
                    type: 'bar',
                    stacked: false,  // Not stacked since we're using only one series
                    toolbar: {
                        show: false, // Hide toolbar
                    },
                    events: {
                        mounted: function() {
                            // Remove all <title> elements inside the ApexCharts SVG
                            const svgTitles = document.querySelectorAll("#ar-aging-chart .apexcharts-svg title");
                            svgTitles.forEach(title => title.remove());
                        },
                        updated: function() {
                            // Remove all <title> elements inside the ApexCharts SVG
                            const svgTitles = document.querySelectorAll("#ar-aging-chart .apexcharts-svg title");
                            svgTitles.forEach(title => title.remove());
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true, // Horizontal bar chart
                        dataLabels: {
                            enabled: false, // Disable data labels
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: false,
                },
                title: {
                    text: 'A/R AGING',
                    align: 'center',
                    style: {
                        fontSize: '18px',
                        fontWeight: 'light',
                        color: '#FFFFFF',
                        fontFamily: 'Inter, sans-serif',
                    },
                },
                xaxis: {
                    categories: categories, // Aging categories as Y-axis labels
                    labels: {
                        formatter: function (value) {
                            // Convert large numbers to human-readable format like "100K"
                            if (value >= 1000) {
                                return (value / 1000).toFixed(0) + 'K';  // Convert to K
                            }
                            return value;  // Return value for numbers smaller than 1000
                        },
                        style: {
                            colors: '#FFFFFF',
                            fontSize: '14px',
                            fontFamily: 'Inter, sans-serif',
                        },
                    },
                    axisBorder: {
                        show: true,
                        color: '#FFFFFF', // X-axis line color
                        height: 2,        // X-axis line thickness
                    },
                    axisTicks: {
                        show: true,
                        color: '#FFFFFF', // X-axis tick color
                        height: 8,        // X-axis tick thickness
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value; // Return the Y-axis categories
                        },
                        style: {
                            colors: '#FFFFFF',
                            fontSize: '13px',
                            fontFamily: 'Inter, sans-serif',
                        },
                    },
                    title: {
                        text: undefined,
                    },
                },
                tooltip: {
                    theme: 'dark', // Dark tooltip theme
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Arial',
                        color: '#FFFFFF',
                    },
                    fillSeriesColor: false, // Don't match series color to tooltip
                    marker: {
                        show: true,
                    },
                    x: {
                        show: true,
                    },
                    y: {
                        formatter: function (val) {
                            return '$' + val.toLocaleString(); // Format tooltip values as currency
                        },
                    },
                },
                grid: {
                    show: false, // Disable grid lines
                },
                fill: {
                    opacity: 1,
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                    labels: {
                        colors: ['#FFFFFF'], // Custom colors for legend labels
                    },
                    fontSize: '14px',
                    fontFamily: 'Inter, sans-serif',
                },
            },
        }
    };

    // Get the chart options for this building
    const chartOptions = generateChartOptions();

    return (
        <Card className="black-card">
            <Card.Body className="text-white px-2 pt-3 pb-0">
                <Chart options={chartOptions.options} series={chartOptions.series} type="bar" height="330" className="d-block m-auto" id="ar-aging-chart" />
            </Card.Body>
        </Card>
    );
}

// Define the prop types for the component
ArAging.propTypes = {
    data: PropTypes.shape({
        '1_30_days': PropTypes.object.isRequired,
        '31_60_days': PropTypes.object.isRequired,
        '61_90_days': PropTypes.object.isRequired,
        '91_120_days': PropTypes.object.isRequired,
        '121_plus_days': PropTypes.object.isRequired,
    }),
};

export default ArAging
