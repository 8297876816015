import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import {Button, Form, Modal} from "react-bootstrap";

import BookingWizard from "./BookingWizard";

const BuildingMeetingBookings = ({ customer, buildings }) => {
    // State to control modal visibility
    const [isBookingModalVisible, setIsBookingModalVisible] = useState(false);

    // Function to show the modal
    const showBookingModal = () => setIsBookingModalVisible(true);

    // Function to hide the modal
    const hideBookingModal = () => setIsBookingModalVisible(false);

    return (
        <>
            {/* Button to trigger showing the book a meeting modal */}
            <button onClick={showBookingModal}
                    className="flex flex-col items-center justify-center bg-black w-full h-full rounded-lg p-1">
                <i className="fa fa-calendar fa-3x text-white" aria-hidden="true"></i>
                <span className="text-white block uppercase">Book a meeting</span>
            </button>

            {/* Modal for building inquiry form */}
            <Modal show={isBookingModalVisible} onHide={hideBookingModal} size="lg" className="modal-dark">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 className="text-white text-lg font-weight-light tracking-wide mb-0">Building Book A Meeting</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="overflow-hidden">
                        <BookingWizard
                            customer={customer}
                            buildings={buildings}
                            hideBookingModal={hideBookingModal}
                            showBookingModal={showBookingModal}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default BuildingMeetingBookings;

