import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Chart from 'react-apexcharts';

const LeaseActivities = ({ data }) => {
    // Check if data is defined
    if (!data) {
        return (
            <Card className="black-card py-3">
                <Card.Body
                    className="d-flex flex-column font-weight-light justify-content-center text-center text-white p-0">
                    <h3 className="text-lg font-weight-light text-uppercase tracking-wide mb-1">Lease Activities</h3>
                    <div className="px-2 block text-sm font-weight-light leading-snug tracking-wide">Chart generation failed: Insufficient Data</div>
                </Card.Body>
            </Card>
        );
    }

    const generateChartOptions = () => {
        // Prepare series data for Move In, Move Out, and Renewal counts
        const moveInData = data.map(item => item.move_in_count);
        const moveOutData = data.map(item => item.move_out_count);
        const renewalData = data.map(item => item.renewal_count);

        // Prepare categories using month labels (e.g., "AUG 24", "SEP 24")
        const categories = data.map(item => item.month_year_label);

        return {
            series: [
                {
                    name: 'Move In',
                    data: moveInData,
                    color: '#B38840',
                },
                {
                    name: 'Move Out',
                    data: moveOutData,
                    color: '#000000',
                },
                {
                    name: 'Renewal',
                    data: renewalData,
                    color: '#FFFFFF',
                }
            ],
            options: {
                chart: {
                    type: 'bar',
                    toolbar: {
                        show: false,
                    },
                    events: {
                        mounted: function() {
                            // Remove all <title> elements inside the ApexCharts SVG
                            const svgTitles = document.querySelectorAll("#lease-activities-chart .apexcharts-svg title");
                            svgTitles.forEach(title => title.remove());
                        },
                        updated: function() {
                            // Remove all <title> elements inside the ApexCharts SVG
                            const svgTitles = document.querySelectorAll("#lease-activities-chart .apexcharts-svg title");
                            svgTitles.forEach(title => title.remove());
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded',
                        dataLabels: {
                            enabled: false,
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: false,
                },
                title: {
                    text: 'MOVE IN / MOVE OUT / RENEWALS',
                    align: 'center',
                    style: {
                        fontSize: '18px',
                        fontWeight: 'light',
                        color: '#FFFFFF',
                        fontFamily: 'Inter, sans-serif',
                    },
                },
                xaxis: {
                    categories: categories,
                    labels: {
                        formatter: function (value) {
                            return value;
                        },
                        style: {
                            colors: '#FFFFFF',
                            fontSize: '13px',
                            fontFamily: 'Inter, sans-serif',
                        },
                    },
                    axisTicks: {
                        show: true,
                        color: '#FFFFFF',
                        height: 8,
                    },
                    axisBorder: {
                        show: true,
                        color: '#FFFFFF',
                    },
                    crosshairs: {
                        show: false
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value;
                        },
                        style: {
                            colors: '#FFFFFF',
                            fontSize: '14px',
                            fontFamily: 'Inter, sans-serif',
                        },
                    },
                    title: {
                        text: undefined,
                    },
                    axisBorder: {
                        show: true,
                        color: '#FFFFFF',
                        offsetX: -1,
                        offsetY: 0
                    },
                    stepSize: 1,
                },
                grid: {
                    show: false, // Disable both horizontal and vertical grid lines
                },
                fill: {
                    opacity: 1,
                    colors: ['#A67C00', '#FFFFFF'],
                },
                tooltip: {
                    theme: 'dark',
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Arial',
                        color: '#FFFFFF',
                    },
                    fillSeriesColor: false,
                    marker: {
                        show: true,
                    },
                    x: {
                        show: true,
                    },
                    y: {
                        formatter: function (value) {
                            return value;
                        },
                    },
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                    labels: {
                        colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF'],
                    },
                    fontSize: '14px',
                    fontFamily: 'Inter, sans-serif',
                }
            },
        }
    };

    // Get the chart options for this building
    const chartOptions = generateChartOptions();

    return (
        <Card className="black-card">
            <Card.Body className="text-white px-2 pt-3 pb-0">
                <Chart options={chartOptions.options} series={chartOptions.series} type="bar" height="330" className="d-block m-auto" id="lease-activities-chart" />
            </Card.Body>
        </Card>
    );
}

// Define the prop types for the component
LeaseActivities.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            month_year_label: PropTypes.string.isRequired,
            move_in_count: PropTypes.string.isRequired,
            move_out_count: PropTypes.string.isRequired,
            renewal_count: PropTypes.string.isRequired,
        })
    ),
};

export default LeaseActivities
