const BuildingSearchBar = ({ onChange }) => {
    return (
        <div className="relative h-100">
            {/* Search icon positioned inside the input field */}
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <i className="fa fa-search fa-2x text-black" aria-hidden="true"></i>
            </div>
            {/* Search input field with onChange event to handle input changes */}
            <input
                type="search"
                className="block w-full h-full py-3 pl-16 text-2xl text-gray-900 border-4 border-black rounded-lg text-center"
                onChange={(e) => onChange(e.target.value)} // Call onChange with the current input value
                placeholder="SEARCH FOR BUILDING"
            />
        </div>
    );
}

export default BuildingSearchBar;
