import {useState} from "react";
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {Button, Form, Modal} from "react-bootstrap";
import {ownerEnquiry} from './../../../../redux/actions/listActions';

const BuildingInquiry = ({ ownerId }) => {
    const dispatch = useDispatch(); // Initialize useDispatch hook to dispatch Redux actions

    // State to control modal visibility
    const [isInquiryModalVisible, setIsInquiryModalVisible] = useState(false);

    // Function to show the modal
    const showInquiryModal = () => setIsInquiryModalVisible(true);

    // Function to hide the modal
    const hideInquiryModal = () => setIsInquiryModalVisible(false);

    // State to store the selected file's name, with default label
    const [fileName, setFileName] = useState('Choose file');

    // Handle file input change
    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the first selected file
        setFileName(file ? file.name : 'Choose file'); // Update state with file name or revert to default if no file selected
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        const formData = new FormData(); // Create a new FormData object to send data as multipart/form-data

        // Append form data fields
        formData.append('userId', ownerId); // Append userId (building owner ID)
        formData.append('comments', e.target.elements.comments.value); // Append inquiry comments
        formData.append('document', e.target.attachment?.files[0]); // Append the selected file (if any)

        // Dispatch the ownerEnquiry action to Redux store with formData
        dispatch(ownerEnquiry(formData));

        // Hide the modal after form submission
        hideInquiryModal();
    };

    return (
        <>
            {/* Button to trigger showing the inquiry modal */}
            <button onClick={showInquiryModal}
                    className="bg_black_opacity d-flex flex-column align-items-center justify-content-center text-white rounded-lg border-4 border-black border-opacity-10 p-2 w-100">
                <i className="fa fa-comments-o fa-2x text-white" aria-hidden="true"></i>
                <span className="d-block text-sm text-uppercase font-weight-semibold mt-2">Inquiry</span>
            </button>

            {/* Modal for building inquiry form */}
            <Modal show={isInquiryModalVisible} onHide={hideInquiryModal} size="lg" className="modal-dark">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 className="text-white text-lg font-weight-light tracking-wide mb-0">Building Owner Inquiry</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        {/* Text area for entering inquiry comments */}
                        <Form.Group>
                            <Form.Label htmlFor="comments" className="text-light">Details of Your Inquiry</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                placeholder="Enter your comments or inquiry"
                                name="comments"
                                minLength="25"
                                maxLength="160"
                                required
                            />
                        </Form.Group>

                        {/* File input field to attach supporting documents */}
                        <Form.Group>
                            <Form.Label className="text-light">Attach Supporting Document</Form.Label>
                            <div className="custom-file">
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="attachment"
                                    onChange={handleFileChange} // Update file name on change
                                />
                                <label className="custom-file-label" htmlFor="attachment">
                                    {fileName} {/* Show the selected file name */}
                                </label>
                            </div>
                        </Form.Group>

                        <div className="row">
                            <div className="col-md-6 offset-md-6">
                                {/* Submit button to send the form */}
                                <Button variant="primary" type="submit" className="w-100">
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Form>

                    {/* Note to inform the user about the response time */}
                    <div className="mt-3 text-center">
                        <small className="text-muted text-base">We'll get back to you within 48 hours.</small>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
// Define the prop types for the component
BuildingInquiry.propTypes = {
    ownerId: PropTypes.number.isRequired,
};


export default BuildingInquiry;
