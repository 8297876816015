import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

// Takes 'to' (link path), 'iconClass' (icon CSS class), and 'label' (link text) as props
const NavItemLink = ({ to, iconClass, label }) => (
    <Nav.Item as="li" className="col-md-4">
        {/* NavLink from react-router-dom for internal navigation */}
        <NavLink
            to={to}
            className="bg_black_opacity d-flex flex-column align-items-center justify-content-center text-white rounded-lg border-4 border-black border-opacity-10 p-2"
        >
            {/* Icon and label display */}
            <i className={`${iconClass} fa-2x`} aria-hidden="true"></i>
            <span className="nav-text d-block text-sm text-uppercase font-weight-semibold mt-2">{label}</span>
        </NavLink>
    </Nav.Item>
);

const BuildingDetailsNavlink = ({ buildingId }) => {
    // Define the links with path, icon, and label in a data array
    const navLinks = [
        {
            path: `/multi-residential/${buildingId}/financial`, // Dynamic link with buildingId
            iconClass: "fa fa-line-chart", // Icon for financial link
            label: "Financial", // Display text for financial link
        },
        {
            path: `/multi-residential/${buildingId}/operational`,
            iconClass: "fa fa-home",
            label: "Operational",
        },
        {
            path: `/multi-residential/${buildingId}/occupancy`,
            iconClass: "fa fa-users",
            label: "Occupancy",
        },
    ];

    return (
        <Nav as="ul" className="multi-residential-nav row">
            {/* Map over the navLinks array to render NavItemLink components dynamically */}
            {navLinks.map((link) => (
                <NavItemLink
                    key={link.label} // Use the label as a unique key
                    to={link.path} // Link path
                    iconClass={link.iconClass} // CSS class for the icon
                    label={link.label} // Text displayed for the link
                />
            ))}
        </Nav>
    );
};

// Define the prop types for the BuildingDetailsNavlink component
BuildingDetailsNavlink.propTypes = {
    buildingId: PropTypes.string.isRequired,
};

export default BuildingDetailsNavlink;
