import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Chart from 'react-apexcharts';

const UnitsMaintenance = ({ data }) => {
    // Check if data is defined
    if (!data) {
        return (
            <Card className="white-card py-3">
                <Card.Body
                    className="d-flex flex-column font-weight-light justify-content-center text-center text-white p-0">
                    <h3 className="text-lg font-weight-light text-uppercase tracking-wide mb-1">Maintenance</h3>
                    <div className="px-2 block text-sm font-weight-light leading-snug tracking-wide">Chart generation failed: Insufficient Data</div>
                </Card.Body>
            </Card>
        );
    }

    const generateChartOptions = () => {
        // Extract the data from the array
        const categories = data.map((unit) => unit.description);
        const inProgressData = data.map((unit) => unit.open_tickets);
        const closedData = data.map((unit) => unit.closed_tickets);

        return {
            series: [
                {
                    name: 'In Progress',
                    data: inProgressData,
                    color: '#000000',
                },
                {
                    name: 'Closed',
                    data: closedData,
                    color: '#B38840'
                },
            ],
            options: {
                chart: {
                    type: 'bar',
                    stacked: true,
                    toolbar: {
                        show: false,
                    },
                    events: {
                        mounted: function() {
                            // Remove all <title> elements inside the ApexCharts SVG
                            const svgTitles = document.querySelectorAll("#units-maintenance-chart .apexcharts-svg title");
                            svgTitles.forEach(title => title.remove());
                        },
                        updated: function() {
                            // Remove all <title> elements inside the ApexCharts SVG
                            const svgTitles = document.querySelectorAll("#units-maintenance-chart .apexcharts-svg title");
                            svgTitles.forEach(title => title.remove());
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            enabled: false,
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: false,
                },
                title: {
                    text: 'MAINTENANCE',
                    align: 'center',
                    style: {
                        fontSize: '18px',
                        fontWeight: 'light',
                        color: '#000000',
                        fontFamily: 'Inter, sans-serif',
                    },
                },
                xaxis: {
                    categories: categories,
                    labels: {
                        formatter: function (value) {
                            return value;
                        },
                        style: {
                            colors: '#000000',
                            fontSize: '14px',
                            fontFamily: 'Inter, sans-serif',
                        },
                    },
                    axisBorder: {
                        show: true,
                        color: '#000000',
                        height: 2,
                    },
                    axisTicks: {
                        show: true,
                        color: '#000000',
                        height: 8,
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value, index) {
                            return value;
                        },
                        style: {
                            colors: '#000000',
                            fontSize: '13px',
                            fontFamily: 'Inter, sans-serif',
                        },
                    },
                    title: {
                        text: undefined
                    },
                },
                tooltip: {
                    theme: 'dark',
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Inter, sans-serif',
                        color: '#000000'
                    },
                    fillSeriesColor: false,
                    marker: {
                        show: true,
                    },
                    x: {
                        show: true,
                    },
                    y: {
                        formatter: function (value) {
                            return value;
                        }
                    }
                },
                grid: {
                    show: false, // Disable both horizontal and vertical grid lines
                },
                fill: {
                    opacity: 1,
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                    labels: {
                        colors: ['#000000', '#000000'],
                    },
                    fontSize: '14px',
                    fontFamily: 'Inter, sans-serif',
                }
            },
        }
    };

    // Get the chart options for this building
    const chartOptions = generateChartOptions();

    return (
        <Card className="white-card">
            <Card.Body className="text-black px-2 pt-3 pb-0">
                <Chart options={chartOptions.options} series={chartOptions.series} type="bar" height="330" className="d-block m-auto" id="units-maintenance-chart" />
            </Card.Body>
        </Card>
    );
}

// Define the prop types for the component
UnitsMaintenance.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string.isRequired,
            open_tickets: PropTypes.string.isRequired,
            closed_tickets: PropTypes.string.isRequired,
        })
    ),
};

export default UnitsMaintenance
