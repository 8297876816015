import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import { useSelector } from "react-redux";

const BuildingCard = ({ building }) => {
    // Get helper function from Redux store
    const { helper } = useSelector((state) => ({
        helper: state.dataPack.helper,
    }));

    // Create chart options based on building data
    const generateChartOptions = (building) => {
        const occupancyRate = building.occupancy_rate;

        return {
            series: [occupancyRate], // Set the occupancy rate as the chart data
            options: {
                chart: {
                    type: 'radialBar', // Radial bar chart
                    offsetY: -20,
                    sparkline: {
                        enabled: true // Hide chart axes
                    },
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#fff", // White background for the bar track
                            strokeWidth: '97%',
                            margin: 5,
                        },
                        dataLabels: {
                            name: {
                                show: false, // Hide the name label
                            },
                            value: {
                                show: false, // Hide the value label
                                formatter: function (val) {
                                    return `${Math.round(val)}%`; // Format value as a percentage
                                },
                            },
                        },
                    },
                },
                fill: {
                    type: 'solid',
                    colors: '#b38840', // Set the color of the bar
                },
                tooltip: {
                    enabled: true,
                    theme: 'dark',
                    y: {
                        formatter: function (val) {
                            return `${Math.round(val)}% Occupancy Rate for ${building.name}`; // Tooltip text
                        },
                        title: {
                            formatter: function () {
                                return '';
                            }
                        }
                    },
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Inter, sans-serif',
                    },
                },
            },
        }
    }

    // Get the chart options for this building
    const chartOptions = generateChartOptions(building);

    return (
        <Card className="building-card bg_black_opacity">
            <Card.Body className="d-flex flex-column font-weight-light justify-content-center text-center text-white space-y-2">
                <h3 className="building-name text-xl font-weight-light text-uppercase tracking-wide mb-0">{building.name}</h3>
                <div className="position-relative mx-n3">
                    {/* Render the chart */}
                    <Chart options={chartOptions.options} series={chartOptions.series} type="radialBar"/>
                    <i className="d-block fa fa-building-o fa-3x mt-n5" aria-hidden="true"></i>
                </div>
                <div className="text-center">
                    {/* Display the formatted funds held */}
                    <span className="building-funds block text-2xl font-weight-light leading-snug tracking-wide">{helper.formatCurrency(building.funds_held)}</span>
                    <small className="d-block text-xs text-uppercase tracking-tight">Funds Held</small>
                </div>
                <p className="building-address text-lg text-center text-uppercase leading-snug">{building.full_address}</p>
            </Card.Body>
        </Card>
    );
};

// Define the prop types for the BuildingCard component
BuildingCard.propTypes = {
    building: PropTypes.shape({
        name: PropTypes.string.isRequired,
        occupancy_rate: PropTypes.number.isRequired,
        full_address: PropTypes.string.isRequired,
        funds_held: PropTypes.number.isRequired,
    }).isRequired,
};

export default BuildingCard;
