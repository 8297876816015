import html2pdf from "html2pdf.js";
import logo from  './../../../../../component/assets/images/rentperks-logo.jpg';

const BuildingChartsExport = ({ building, reference, config: { filename = 'building-charts', title = 'Charts', format = 'a4', orientation = 'portrait' } }) => {
    // Function to handle PDF download
    const downloadPdf = () => {
        const element = reference.current; // Get the HTML element
        const options = {
            margin: [55, 12, 15, 12],
            filename: filename + '.pdf',
            image: { type: 'jpg', quality: 1 },
            html2canvas: {
                scale: 2,
                dpi: 192,
                letterRendering: true,
                useCORS: true,
            },
            jsPDF: { unit: 'mm', format: format, orientation: orientation },
            pagebreak: { mode: 'avoid-all' },
        };

        html2pdf().from(element).set(options).toPdf().get('pdf').then(function (pdf) {
            const totalPages = pdf.internal.getNumberOfPages();
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const headerWidth = pageWidth - 24;

            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);

                // Draw a solid black background that covers the entire page
                pdf.setFillColor(0, 0, 0); // RGB for black
                pdf.rect(12, 12, headerWidth, 34, 'F'); // Draw filled rectangle

                // Center the image dynamically
                const logoWidth = 100; // Set the desired width for the logo (adjust as needed)
                const logoHeight = 20; // Set the height for the logo (adjust as needed)
                const logoXPosition = (pageWidth - logoWidth) / 2; // Center the logo horizontally

                // Add the centered logo image
                pdf.addImage(logo, 'JPEG', logoXPosition, 12, logoWidth, logoHeight);

                // Add white bold text inside the black section, centered horizontally
                pdf.setTextColor(255, 255, 255); // Set text color to white
                pdf.setFont('Helvetica', 'bold'); // Set font to bold
                pdf.setFontSize(14); // Set font size to 18px

                const pageTitle = (building.name + ' ' + title).toUpperCase(); // Text to be centered
                const pageTitleWidth = pdf.getTextWidth(pageTitle); // Get the page title width
                const pageTitleXPosition = (pageWidth - pageTitleWidth) / 2; // Calculate the x position to center the page title

                pdf.text(pageTitle, pageTitleXPosition, 41); // Place the centered text
            }
        }).save();
    };

    return (
        <>
            {/* Button to trigger showing the inquiry modal */}
            <button onClick={downloadPdf}
                    className="bg_black_opacity d-flex flex-column align-items-center justify-content-center text-white rounded-lg border-4 border-black border-opacity-10 p-2 w-100">
                <i className="fa fa-bar-chart fa-2x text-white" aria-hidden="true"></i>
                <span className="d-block text-sm text-uppercase font-weight-semibold mt-2">Graph</span>
            </button>
        </>
    );
}

export default BuildingChartsExport;
