import React, { useState } from 'react';
import { Form, Alert } from 'react-bootstrap';

const BuildingTImeSetup = (props) => {
    // Set default values
    const [buildingId, setBuildingId] = useState(
        props.buildings.length > 0 ? props.buildings[0].id : ''
    );
    const [timeZone, setTimeZone] = useState('America/Toronto');
    const [hasFormError, setHasFormError] = useState(false);

    const handleNextStep = () => {
        // TODO: Refactor and enhance the form validation logic for better scalability and maintainability.
        if (!buildingId || !timeZone) {
            setHasFormError(true);
            return;
        }

        setHasFormError(false);

        props.update('buildingId', buildingId);
        props.update('timeZone', timeZone);
        props.nextStep();
    }

    return (
        <div className="container-fluid">
            {/* Display Alert only if hasError is true */}
            {hasFormError && (
                <Alert variant="danger">
                    <strong>Booking Validation Errors:</strong>
                    <ul className="mb-0">
                        {!buildingId && (
                            <li>The building field is required.</li>
                        )}

                        {!timeZone && (
                            <li>The timezone field is required.</li>
                        )}
                    </ul>
                </Alert>
            )}
            <div className="row">
                <div className="col-md-6">
                    <Form.Group>
                        <Form.Label>Building</Form.Label>
                        <Form.Control
                            as="select"
                            value={buildingId}
                            onChange={(e) => setBuildingId(e.target.value)}
                        >
                            {props.buildings.map((building) => (
                                <option key={building.id} value={building.id}>
                                    {building.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group>
                        <Form.Label>Timezone</Form.Label>
                        <Form.Control
                            as="select"
                            value={timeZone}
                            onChange={(e) => setTimeZone(e.target.value)}
                        >
                            <option value="America/Toronto">Eastern Time</option>
                            <option value="America/Edmonton">Mountain Time</option>
                            <option value="America/Vancouver">Pacific Time</option>
                        </Form.Control>
                    </Form.Group>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 offset-6">
                    <button className='btn btn-primary btn-block' onClick={handleNextStep}>Continue</button>
                </div>
            </div>
        </div>
    );
};

export default BuildingTImeSetup;
