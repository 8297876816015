import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import BootstrapTable from "react-bootstrap-table-next";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const ShowcaseTable = ({ data }) => {
    // Get helper function from Redux store
    const { helper } = useSelector((state) => ({
        helper: state.dataPack.helper,
    }));

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true,
        },
        {
            dataField: 'unit_type',
            text: 'Unit Type',
            sort: true,
            classes: 'text-center text-xs',
        },
        {
            dataField: 'unit',
            text: 'Suite',
            sort: true,
            classes: 'text-center text-xs',
        },
        {
            dataField: 'days_on_market',
            text: 'Days On Market',
            sort: true,
            classes: 'text-center text-xs',
        },
        {
            dataField: 'vacant_days',
            text: 'Vacant Days',
            sort: true,
            classes: 'text-center text-xs',
        },
        {
            dataField: 'inquiries',
            text: 'Inquiries',
            sort: true,
            classes: 'text-center text-xs',
        },
        {
            dataField: 'ad_links',
            text: 'Ad Links',
            isDummyField: true,
            headerFormatter: () => (
                <div className="d-flex justify-content-center align-items-center">
                    <span>AD LINKS</span>
                    <span className="ml-1">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip className="adlinks-info-tooltip">
                                    Ads shown below do not include our social media platforms - we use them, but they are geo-fenced and based on individual agent settings.
                                </Tooltip>
                            }
                        >
                        <i className="fa fa-info-circle fa-lg cursor-pointer" aria-hidden="true"></i>
                        </OverlayTrigger>
                    </span>
                </div>
            ),
            formatter: (cell, row) => (
                row.ad_links && row.ad_links.length > 0 ? (
                    row.ad_links.map((link, index) => (
                        <a
                            key={index}
                            href={link.url}
                            className="badge badge-dark d-inline-block py-1 mx-1"
                            title={`View listing on ${link.platform}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="fa fa-external-link" aria-hidden="true"></i> {link.platform}
                        </a>
                    ))
                ) : null
            ),
            classes: 'text-center text-sm',
        },
    ];

    // Define the default sorting (amount column in descending order)
    const defaultSorted = [{
        dataField: 'unit', // default sort column
        order: 'asc' // 'asc' for ascending, 'desc' for descending
    }];

    return (
        <BootstrapTable
            wrapperClasses='building-showcase-table-container scrollable-table-container'
            classes='building-showcase-table scrollable-table table-divider'
            bootstrap4
            keyField='id'
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            noDataIndication='No Showcase Records Found'
        />
    );
};

// Define the prop types for the component
ShowcaseTable.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            unit_type: PropTypes.string.isRequired,
            unit: PropTypes.number.isRequired,
            days_on_market: PropTypes.number.isRequired,
            vacant_days: PropTypes.number.isRequired,
            inquiries: PropTypes.number.isRequired,
        })
    ).isRequired,
};

export default ShowcaseTable;
