import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import TENANCY_ICON from './../../../../component/assets/img/owner-portal/home_white.png'
import SNAPSHOT from './../../../../component/assets/img/owner-portal/camara.png'
import BUILDING from './../../../../component/assets/img/owner-portal/building.png'
import FOR_LEASE from './../../../../component/assets/img/owner-portal/for_lease.png'
import PLUS from './../../../../component/assets/img/owner-portal/plus.png'
import STAR from './../../../../component/assets/img/owner-portal/star.png'
import SETTINGS from './../../../../component/assets/img/owner-portal/settings.png'

class Panel extends Component { // eslint-disable-line react/prefer-stateless-function
    constructor(props) {
        super(props)

    }

    render() {

        const { props } = this
        const { url, params } = props.match
        const { authData } = this.props
        const secondAuth = authData?.isSecondAuthority
        const isSimulated = !!authData?.simulate;

        let is_active  = (url.includes('folio') ? "active" : '')

        return (
            <>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xl-2 d-flex align-content-between flex-wrap justify-content-center">
                    <Link to="/folio-snap" data-aos="fade-up" data-aos-delay="200" class={`menu_link aos-init aos-animate ${(url.includes('folio-snap') ? "active" : '')}`}>
                        <img src={SNAPSHOT} class="button_icon" />
                        <p>SnapShot</p>
                    </Link>

                    <Link to="/tenancy" data-aos="fade-up" data-aos-delay="200" class={`menu_link aos-init aos-animate ${(url.includes('tenancy') ? "active" : '')}`}>
                        <img src={TENANCY_ICON} class="button_icon" />
                        <p>Tenancy View</p>
                    </Link>

                    <Link to="/building" data-aos="fade-up" data-aos-delay="200" class={`menu_link aos-init aos-animate ${(url.includes('building') ? "active" : '')}`}>
                        <img src={BUILDING} class="button_icon" />
                        <p>Building View</p>
                    </Link>

                    {isSimulated ? (
                        <Link to="/multi-residential" data-aos="fade-up" data-aos-delay="200" class={`menu_link aos-init aos-animate ${(url.includes('multi-residential') ? "active" : '')}`}>
                            <img src={BUILDING} class="button_icon" />
                            <p>Multi - R</p>
                        </Link>
                    ) : null}

                    <Link to="/for-lease" data-aos="fade-up" data-aos-delay="200" class={`menu_link aos-init aos-animate ${(url.includes('for-lease') ? "active" : '')}`}>
                        <img src={FOR_LEASE} class="button_icon" />
                        <p>For Lease</p>
                    </Link>
                    <Link to="/add-property" data-aos="fade-up" data-aos-delay="200" class={`menu_link aos-init aos-animate ${(url.includes('property') ? "active" : '')}`}>
                        <img src={PLUS} class="button_icon" />
                        <p>Add/Edit Property</p>
                    </Link>
                    <Link to="/rewards" data-aos="fade-up" data-aos-delay="200" class={`menu_link aos-init aos-animate ${(url.includes('rewards') ? "active" : '')}`}>
                        <img src={STAR} class="button_icon" />
                        <p>Rewards</p>
                    </Link>
                    <Link to="/profile" data-aos="fade-up" data-aos-delay="200" class={`menu_link aos-init aos-animate ${(url.includes('profile') ? "active" : '')}`}>
                        <img src={SETTINGS} class="button_icon" />
                        <p>profile</p>
                    </Link>
                </div>
            </>
        )
    }
}
export default Panel