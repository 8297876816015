import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Chart from 'react-apexcharts';

const ExpiringLeases = ({ data }) => {
    // Check if data is defined
    if (!data) {
        return (
            <Card className="black-card py-3">
                <Card.Body
                    className="d-flex flex-column font-weight-light justify-content-center text-center text-white p-0">
                    <h3 className="text-lg font-weight-light text-uppercase tracking-wide mb-1">Expiring Leases</h3>
                    <div className="px-2 block text-sm font-weight-light leading-snug tracking-wide">Chart generation failed: Insufficient Data</div>
                </Card.Body>
            </Card>
        );
    }

    const generateChartOptions = () => {
        // Define the categories
        const categories = ['30 Days', '60 Days', '90 Days'];

        let series = [data['1_30_days'], data['31_60_days'], data['61_90_days']];

        // Set series to an empty array if all values are zero
        series = series.every(value => value === 0) ? [] : series;

        return {
            series: series,
            options: {
                chart: {
                    type: 'donut',
                    toolbar: {
                        show: false,
                    },
                    events: {
                        mounted: function() {
                            // Remove all <title> elements inside the ApexCharts SVG
                            const svgTitles = document.querySelectorAll("#expiring-leases-chart .apexcharts-svg title");
                            svgTitles.forEach(title => title.remove());
                        },
                        updated: function() {
                            // Remove all <title> elements inside the ApexCharts SVG
                            const svgTitles = document.querySelectorAll("#expiring-leases-chart .apexcharts-svg title");
                            svgTitles.forEach(title => title.remove());
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                labels: categories,
                colors: ['#000000', '#FFFFFF', '#808080', '#B38840'],
                title: {
                    text: 'EXPIRING LEASES',
                    align: 'center',
                    style: {
                        fontSize: '18px',
                        fontWeight: 'light',
                        color: '#FFFFFF',
                        fontFamily: 'Inter, sans-serif',
                    },
                },
                stroke: {
                    show: false,
                    width: 0,
                },
                tooltip: {
                    theme: 'dark',
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Inter, sans-serif',
                        color: '#000000'
                    },
                    fillSeriesColor: false,
                    marker: {
                        show: true,
                    },
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                    labels: {
                        colors: '#FFFFFF',
                    },
                    fontSize: '12px',
                    fontFamily: 'Inter, sans-serif',
                    formatter: function(value, opts) {
                        if (opts.w.globals.series[opts.seriesIndex] !== undefined) {
                            return value + " - " + opts.w.globals.series[opts.seriesIndex];
                        }
                        return value;
                    }
                },
                noData: {
                    text: 'No Expiring Leases',
                    align: 'center',
                    verticalAlign: 'middle',
                    style: {
                        fontSize: '16px',
                        fontFamily: 'Inter, sans-serif',
                        color: '#FFFFFF',
                    },
                },
            },
        }
    };

    // Get the chart options for this building
    const chartOptions = generateChartOptions();

    return (
        <Card className="black-card">
            <Card.Body className="text-white px-2 pt-3 pb-4">
                <Chart options={chartOptions.options} series={chartOptions.series} type="donut" height={chartOptions.series.length ? 350 : 320} className="d-block m-auto" id="expiring-leases-chart" />
            </Card.Body>
        </Card>
    );
}

// Define the prop types for the component
ExpiringLeases.propTypes = {
    data: PropTypes.shape({
        '1_30_days': PropTypes.number.isRequired,
        '31_60_days': PropTypes.number.isRequired,
        '61_90_days': PropTypes.number.isRequired,
        'not_expiring': PropTypes.number.isRequired,
    }),
};

export default ExpiringLeases
