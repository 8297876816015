import {
    GET_PROPERTY,
    LEDGER_LIST,
    LEASE_DETAILS,
    OWNER_ENQUIRY,
    PROPERTY_BILLS,
    TENANT_LIST,
    GET_FORLEASE_PROPERTY,
    PROFILE_PROPERTY,
    FOR_LEASE,
    DOCUMENTS_BILLS,
    LOADING_COMPONENT,
    AUTH_USER,
    FORGOT_PASSWORD,
    GET_PROFILE,
    CHANGE_PASSWORD,
    GET_LOGOUT,
    GET_BUILDING,
    GET_BUILDING_PROPERTY,
    UPDATE_PROFILE,
    ADD_BANK_DETAILS,
    UPDATE_BANK_DETAILS,
    DELETE_BANK_DETAILS,
    SELECT_BANK,
    ADD_SECOND_AUTHORITY,
    DELETE_SECOND_AUTHORITY,
    USER_REDIRECT,
    FOLIO_DATA,
    APPLY_NON_RESIDENT,
    OWNER_LIST,
    GET_RESOURCES,
    GET_WC_RESOURCES,
    BUILDING_LEDGERS,
    RECENT_RENT,
    FOLIO_LEDGERS,
    CLEAN_UP_DATA,
    CHECK_SLA_SIGNED,
    GET_PROPERTY_TYPE,
    GET_PROPERTY_LOCATION,
    STATE_CITY,
    PROPERTY_UTILITY,
    PROPERTY_MEDIA,
    USER_NOTIFICATION,
    PENDING_SLA,
    SIGNED_SLA
} from './types'

import Promise from 'axios';
import constants from './../../../component/constants'
import { store } from 'react-notifications-component';


const {APP_URL, SERVER_URL}     = constants
const sanctum_url   = constants.SERVER_URL+constants.SANCTUM_URL

let axiosConfig = {
    headers: {
        'Content-Type': 'multipart/form-data',
        "Access-Control-Allow-Origin": "*",
    }
};
Promise.defaults.baseURL = "http://127.0.0.1:8000"

    const data  = {loader: true}


    export const alertTest = () => {
        success('test')
    }
/**
 * generate SLA PDF with property ID
 * 
 * @param {int} propertyId 
 * @return {*}
 */
export const renderSlaPdf = (propertyId) => dispatch => {
    
    processing(dispatch, true)
    Promise.get(APP_URL+'generate/pdf/' + propertyId)
        
    .then( response => {

        processing(dispatch, false) 

        if( response?.data?.message )
            warning( ""+ response?.data?.message)
        else
            window.open(response.data)
        
    })
    .catch( __ =>  {
        
    })
}
/**
 * get property details by customer ID
 * 
 * @param {int} ownerId 
 * @return {response} redux store
*/
export const userAuthenticate = (data) => dispatch => {

    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'auth/login', data)
        
            .then( (response => dispatch({
                type: AUTH_USER,
                payload: response.data
            })))
            .then( userData =>  {

                localStorage.setItem("userAccessInfo", JSON.stringify(userData.payload))
                localStorage.setItem("isSlaSigned", userData.payload?.user_detail?.is_sla_signed)

                if(userData?.payload?.user?.simulate)
                    localStorage.setItem("simulate", userData?.payload?.user?.customer_id)
            })
            .then( data => dispatch({
                type: LOADING_COMPONENT,
                payload: false
            }))
            .then( _ => {
                success( "Login Successfully!")
                processing(dispatch, false)
            })
            .catch( error => {
                warning( "Invalid Credentails. Please check Email and Password then login again!")
                processing(dispatch, false)
            })
    });
}

/**
 * forget password
 * 
 * @param {int} ownerId 
 * @return {response} redux store
*/
export const forgetPassword = (data) => dispatch => {

    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'forgot-password', data)
        
            .then( (response => dispatch({
                type: FORGOT_PASSWORD,
                payload: response.data
            })))
            .then( data => dispatch({
                type: LOADING_COMPONENT,
                payload: false
            }))
            .then( _ => {
                success( "Password Reset Link Sent on Email Successfully.")
                processing(dispatch, false)
            })
            .catch( error => {
                warning( "Invalid Credentails. Email Id Not Exists!")
                processing(dispatch, false)
            })
    });
}

/**
 * forget password
 * 
 * @param {int} ownerId 
 * @return {response} redux store
*/
export const changePassword = (data) => dispatch => {

    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'change-password', data)
        
            .then( (response => dispatch({
                type: CHANGE_PASSWORD,
                payload: response.data
            })))
            .then( response => { 
                if(response.payload.type == 'error'){
                    warning(response.payload.message)
                } else {
                    success(response.payload.message)
                    window.location.href = '/'
                }           
                processing(dispatch, false)
            })
            .catch( error => {
                warning( "Somthing Wrong!")
                processing(dispatch, false)
            })
    });
}

/**
 * get property details by customer ID
 * 
 * @param {int} ownerId 
 * @return {response} redux store
*/
export const userLogout = (data) => dispatch => {

    localStorage.removeItem("userAccessInfo")
    localStorage.removeItem("simulate")
    // dispatch({
    //     type: AUTH_USER,
    //     payload: ''
    // })
}
/**
 * get property details by customer ID
 * 
 * @param {int} ownerId 
 * @return {response} redux store
 */
export const findAutheenticateUser = (authUser) => dispatch => {

    processing(dispatch, true)
    Promise.get(sanctum_url).then(response => {
        Promise.post(APP_URL+'auth/refresh',{
                headers: { 'Authorization': 'Bearer ' + authUser.access_token}
            })
            .then( (response => dispatch({
                type: AUTH_USER,
                payload: response.data
            })))
            .then( (userData =>  {
                localStorage.setItem("userAccessInfo", JSON.stringify(userData.payload))
            }))
            .then( (data => dispatch({
                type: LOADING_COMPONENT,
                payload: false
            }))
            .catch( error => { console.log(error, 'eooror');
                warning( "Invalid Credentails. Please check Email and Password and login again!")
            })
        );
    });
}

/**
 * get property details by customer ID
 * 
 * @param {int} ownerId 
 * @return {response} redux store
 */
export const findOwnerProperty = (ownerId) => dispatch => {

    processing(dispatch, true)

    Promise.get(APP_URL+'property/list/'+ownerId)
    
        .then( (response => dispatch({
            type: GET_PROPERTY,
            payload: response.data
        })))
        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        }))
    );
}

/**
 * get property details by customer ID
 * 
 * @param {int} ownerId 
 * @return {response} redux store
 */
 export const findOwnerBuilding = (ownerId) => dispatch => {

    processing(dispatch, true)

    Promise.get(APP_URL+'building/list/'+ownerId)
    
        .then( (response => dispatch({
            type: GET_BUILDING,
            payload: response.data
        })))
        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        }))
    );
}

/**
 * get property details by building ID
 * 
 * @param {int} group Id 
 * @return {response} redux store
 */
 export const findBuildingProperty = (data) => dispatch => {

    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'building/property', data)
        
            .then( (response => dispatch({
                type: GET_BUILDING_PROPERTY,
                payload: response.data
            })))
            .then( (_ => dispatch({
                type: LOADING_COMPONENT,
                payload: false
            })))
            .then( _ => dispatch({
                type: LOADING_COMPONENT,
                payload: false
            }))
            .catch( error => { 
                processing(dispatch, false)
                warning( ""+error)
            }
        );
    })
}

/**
 * get property ledger list by property ID
 * 
 * @param {int} propertyId 
 * @return {response} redux store
 */
export const findPropertyLedger = (data) => dispatch => {

    processing(dispatch, true)

    Promise.get(sanctum_url).then(response => {
        
        Promise.post(APP_URL+'property/ledger', {data: data})
        
            .then( (response => dispatch({
                type: LEDGER_LIST,
                payload: response.data
            })))

            .then( (data => dispatch({
                type: LOADING_COMPONENT,
                payload: false
            }))
        );
    });
}

/**
 * get property lease details by property ID
 * 
 * @param {int} propertyId 
 * @return {response} redux store
 */
 export const findLeaseDetails = (propertyId) => dispatch => {

    processing(dispatch, true)

    Promise.get(APP_URL+'lease/details/'+propertyId)
    
        .then( (response => dispatch({
            type: LEASE_DETAILS,
            payload: response.data
        })))

        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        }))
    );
}

/**
 * get property bills by property ID
 * 
 * @param {int} propertyId 
 * @return {response} redux store
 */
 export const findPropertyBill = (propertyId) => dispatch => {

    processing(dispatch, true)

    Promise.get(APP_URL+'property/bills/'+propertyId)
    
        .then( (response => dispatch({
            type: PROPERTY_BILLS,
            payload: response.data
        }))
    );
}

/**
 * get property documents and bills by property ID
 * 
 * @param {int} propertyId 
 * @return {response} redux store
 */
 export const findPropertyDocument = (propertyId) => dispatch => {

    processing(dispatch, true)

    Promise.get(APP_URL+'property/documentBills/'+propertyId)
    
        .then( (response => dispatch({
                type: DOCUMENTS_BILLS,
                payload: response.data
        })))
        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        }))
    );
}

/**
 * get property owner enquiry
 * 
 * @param {string} data 
 * @return {response} redux store
 */
 export const ownerEnquiry = (data) => dispatch => {
 
    Promise.get(sanctum_url).then(response => {

        Promise.post(APP_URL+'owner/enquiry', data, axiosConfig)

        .then( response => {
            if (response?.data?.status) {
                success(response?.data?.message);
            } else {
                warning(response?.data?.message);
            }
        })
        .catch( response => {
            warning(response?.data?.message);
        });
    });
    return true
}

export const handleImageUpload = (data) => dispatch =>{
    
    processing(dispatch, true)

    Promise.get(sanctum_url).then(response => {

        // Promise.defaults.baseURL = "http://127.0.0.1:8000"
        Promise.post(APP_URL+'document/upload', data, axiosConfig)
        
        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        })))
        .then( response => {
            success("Document has been submitted successfully!");
        })
        .catch( response => { 
        });
    });
}

/**
 * loading global component events
 * 
 * @param {array} data 
 * @return {response} redux store
 */
const processing = (dispatch, data)  => {
    
    dispatch({
        type: LOADING_COMPONENT,
        payload: data
    })
}

const success  = (msg) => {
    store.addNotification({
        title: "Success!",
        message: msg,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
    });
}
export const successMessage = (msg) => {
    success(msg)
}

const warning  = (msg) => {
    store.addNotification({
        title: "Error!",
        message: msg,
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
    });
}

/**
 * get for lease data by property ID
 * 
 * @param {int} propertyId 
 * @return {response} redux store
 */
 export const forLease = (propertyId, type) => dispatch => {

    processing(dispatch, true)

    Promise.get(APP_URL+'forlease/'+propertyId+'/'+type)
        .then( (response => dispatch({
            type: FOR_LEASE,
            payload: response.data
        })))
        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        }))
    );
}

/**
 * get property details by customer ID
 * 
 * @param {int} ownerId 
 * @return {response} redux store
 */
 export const findOwnersForLeaseProperty = (ownerId) => dispatch => {

    processing(dispatch, true)

    Promise.get(APP_URL+'leased-property/list/'+ownerId)
    
        .then( (response => dispatch({
            type: GET_FORLEASE_PROPERTY,
            payload: response.data
        })))
        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        }))
    );
}

/**
 * get property details by customer ID
 * 
 * @param {int} ownerId 
 * @return {response} redux store
 */
 export const findOwnersProfileProperty = (ownerId) => dispatch => {

    processing(dispatch, true)

    Promise.get(APP_URL+'leased-property/list/'+ownerId)
    
        .then( (response => dispatch({
            type: PROFILE_PROPERTY,
            payload: response.data
        })))
        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        }))
    );
}

/**
 * get property details by customer ID
 * 
 * @param {int} ownerId 
 * @return {response} redux store
 */
 export const getProfile = (property_id, type, customer_id) => dispatch => {
    processing(dispatch, true)

    Promise.get(APP_URL+'profiles?property_id='+property_id+'&type='+type+'&customer_id='+customer_id)
    
        .then( (response => dispatch({
            type: GET_PROFILE,
            payload: response.data
        })))
        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        }))
    );
}

/**
 * add bank details
 * 
 * @param {int} ownerId 
 * @return {response} redux store
*/
export const addBankDetails = (data) => dispatch => {

    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'add-bank-details', data)
        
            .then( (response => dispatch({
                type: ADD_BANK_DETAILS,
                payload: response.data
            })))
            .then( response => { 
                if(response.payload.type == 'error'){
                    warning(response.payload.message)
                } else {
                    success(response.payload.message)
                    getProfile(data?.property_id, data?.type, data?.customer_id)
                    // window.setTimeout(window.location.href = '/profile', 5000);
                }           
                processing(dispatch, false)
            })
            .catch( error => {
                warning( "Somthing Wrong!")
                processing(dispatch, false)
            })
    });
}

/**
 * update bank details
 * 
 * @param {int} ownerId 
 * @return {response} redux store
*/
export const updateBankDetails = (data) => dispatch => {

    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'update-bank-details', data)
        
            .then( (response => dispatch({
                type: UPDATE_BANK_DETAILS,
                payload: response.data
            })))
            .then( response => { 
                if(response.payload.type == 'error'){
                    // warning(response.payload.message)
                } else {
                    success(response.payload.message)
                    // window.setTimeout(window.location.href = '/profile', 5000);
                }           
                processing(dispatch, false)
            })
            .catch( error => {
                // warning( "Somthing Wrong!")
                // processing(dispatch, false)
            })
    });
}

/**
 * delete bank details
 * 
 * @param {int} ownerId 
 * @return {response} redux store
*/
export const deleteBankDetails = (data, props) => dispatch => {

    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'delete-bank-details', data)
        
            .then( (response => dispatch({
                type: DELETE_BANK_DETAILS,
                payload: response.data
            })))
            .then( async response => { 
                if(response.payload.type == 'error'){
                    warning(response.payload.message)
                } else {
                    await props.getProfile(props?.match?.id, 'country', props?.proData?.profile?.customer_id)
                    success(response.payload.message)
                    // window.setTimeout(window.location.href = '/profile', 5000);
                }           
                processing(dispatch, false)
            })
            .catch( error => {
                warning( "Somthing Wrong!")
                processing(dispatch, false)
            })
    });
}

/**
 * update bank details
 * 
 * @param {int} ownerId 
 * @return {response} redux store
*/
export const selectBank = (data, property_id, props) => dispatch => {
    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'select-bank', data)
        
            .then( (response => dispatch({
                type: SELECT_BANK,
                payload: response.data
            })))
            .then( async response => { 
                if(response.payload.type == 'error'){
                    warning(response.payload.message)
                } else {
                    await props.getProfile(property_id, 'country', props?.proData?.profile?.customer_id)
                    success(response.payload.message)
                }           
                processing(dispatch, false)
            })
            .catch( error => {
                warning( "Somthing Wrong!")
                processing(dispatch, false)
            })
    });
}

/**
 * update profile
 * 
 * @param {int} ownerId 
 * @return {response} redux store
*/
export const updateProfile = (data, props) => dispatch => {

    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'profile-update', data)
        
            .then( (response => dispatch({
                type: UPDATE_PROFILE,
                payload: response.data
            })))
            .then( async response => { 
                if(response.payload.type == 'error'){
                    warning(response.payload.message)
                } else {
                    success(response.payload.message)
                    await props.getProfile(props?.match?.id, 'country', props?.proData?.profile?.customer_id)
                }           
                processing(dispatch, false)
            })
            .catch( error => {
                warning( "Somthing Wrong!")
                processing(dispatch, false)
            })
    });
}

/**
 * Add second authority
 * 
 * @param {int} ownerId 
 * @return {response} redux store
*/
export const addSecondAuthority = (data, props) => dispatch => {

    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'add-second-authority', data)
        
            .then( (response => dispatch({
                type: ADD_SECOND_AUTHORITY,
                payload: response.data
            })))
            .then( async response => { 
                if(response.payload.type == 'error'){
                    warning(response.payload.message)
                } else {
                    success(response.payload.message)
                    await props.getProfile(props?.match?.id, 'country', props?.proData?.profile?.customer_id)
                }           
                processing(dispatch, false)
            })
            .catch( error => {
                warning( "Somthing Wrong!")
                processing(dispatch, false)
            })
    });
}

/**
 * Delete second authority
 * 
 * @param {int} ownerId 
 * @return {response} redux store
*/
export const deleteSecondAuthority = (data, props) => dispatch => {

    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'delete-second-authority', data)
        
            .then( (response => dispatch({
                type: DELETE_SECOND_AUTHORITY,
                payload: response.data
            })))
            .then( async response => { 
                if(response.payload.type == 'error'){
                    warning(response.payload.message)
                } else {
                    await props.getProfile(props?.match?.id, 'country', props?.proData?.profile?.customer_id)
                    success(response.payload.message)
                }           
                processing(dispatch, false)
            })
            .catch( error => {
                warning( "Somthing Wrong!")
                processing(dispatch, false)
            })
    });
}

/**
 * for redirection
 * 
 * @param {int} ownerId 
 * @return {response} redux store
*/
export const forRedirection = (data) => dispatch => {
    dispatch({
        type: USER_REDIRECT,
        payload: data
    })
}
 /* get property details by owner ID
 * 
 * @param {int} group Id 
 * @return {response} redux store
 */
 export const findFolioProperty = (data) => dispatch => {

    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'folio/property', data)
        
            .then( (response => dispatch({
                type: FOLIO_DATA,
                payload: response.data
            })))
            .then( (_ => dispatch({
                type: LOADING_COMPONENT,
                payload: false
            })))
            .then( _ => dispatch({
                type: LOADING_COMPONENT,
                payload: false
            }))
            .catch( error => { 
                processing(dispatch, false)
                warning( ""+error)
            }
        );
    })
}

/**
 * get property details by building ID
 * 
 * @param {int} group Id 
 * @return {response} redux store
 */
 export const generatePDF = (data) => dispatch => {

    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'generate/pdf', data)
        
        .then( (response) => {
            window.open(response.data)
            processing(dispatch, false)
        })
        
        .catch( error => { 
            warning( ""+error)
            processing(dispatch, false)
        });
    })
}

/**
 * generate SLA PDF with property ID
 * 
 * @param {int} propertyId 
 * @return {*}
*/
export const generateBuildingViewPdf = (data) => dispatch => {
     
    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'building/pdf', data)
        
            .then( (response) => {
                console.log('test');
                processing(dispatch, false)
            })
            .catch( error => { 
                processing(dispatch, false)
                warning( ""+error)
            }
        );
    })
}

/**
 * apply non resident for reducing tax
 * 
 * @param {int} ownerId 
 * @return {response} redux store
*/
export const applyNonResident = (data) => dispatch => {

    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'apply-non-resident', data)
        
            .then( (response => dispatch({
                type: APPLY_NON_RESIDENT,
                payload: response.data
            })))
            .then( response => { 
                if(response.payload.type == 'error'){
                    warning(response.payload.message)
                } else {
                    success(response.payload.message)
                }           
                processing(dispatch, false)
            })
            .catch( error => {
                warning( "Somthing Wrong!")
                processing(dispatch, false)
            })
    });
}

/**
 * generate SLA PDF with property ID
 * 
 * @param {int} propertyId 
 * @return {*}
 */
 export const getOwnerList = () => dispatch => {
    
    processing(dispatch, true)
    Promise.get(APP_URL+'owner/list')
    
    .then( (response => dispatch({
        type: OWNER_LIST,
        payload: response.data
    })))
    .then( response => {
        processing(dispatch, false) 
    })
    .catch( __ =>  {
        processing(dispatch, false) 
    })
}

/* get property details by owner ID
 * 
 * @param {int} group Id 
 * @return {response} redux store
 */
export const simulateUser = (data) => dispatch => {

    processing(dispatch, true)
    
    /** clean up date while switching user */
    dispatch({
        type: CLEAN_UP_DATA,
        payload: ""
    })

    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'simulate/user', data)
        
            .then( (response => dispatch({
                type: AUTH_USER,
                payload: response.data
            })))
            .then( userData =>  {
                localStorage.setItem("userAccessInfo", JSON.stringify(userData.payload))
                localStorage.setItem("isSlaSigned", userData.payload?.user_detail?.is_sla_signed)
            })
            .then( data => dispatch({
                type: LOADING_COMPONENT,
                payload: false
            }))
            .then( _ => {
                success( "Simulate Successfully!")

                processing(dispatch, false)
                window.location.href = "/owner/list";
            })
            .catch( error => { 
                processing(dispatch, false)
                warning( ""+error)
            }
        );
    })
}

/**
 * get all resources
 * 
 * @return {response} redux store
 */
 export const findAllResources = (type) => dispatch => {

    processing(dispatch, true)

    Promise.get(APP_URL+'resources/'+type)
    
        .then( (response => dispatch({
            type: GET_RESOURCES,
            payload: response.data
        })))
        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        }))
    );
}

/**
 * get all whats coming
 * 
 * @return {response} redux store
 */
 export const findAllWCResources = (type) => dispatch => {

    processing(dispatch, true)

    Promise.get(APP_URL+'resources/'+type)
    
        .then( (response => dispatch({
            type: GET_WC_RESOURCES,
            payload: response.data
        })))
        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        }))
    );
}


/**
 * get builing property ledger list by property IDs
 * 
 * @param {int} propertyIds
 * @return {response} redux store
 */
 export const getLedgersList = (data) => dispatch => {

    processing(dispatch, true)

    Promise.get(sanctum_url).then(response => {
        
        Promise.post(APP_URL+'building/ledgers', {data: data})
        
            .then( (response => dispatch({
                type: BUILDING_LEDGERS,
                payload: response.data
            })))

            .then( (data => dispatch({
                type: LOADING_COMPONENT,
                payload: false
            }))
        );
    });
}
/**
 * send referral
 * 
 * @param {int} ownerId 
 * @return {response} redux store
*/
export const sendReferral = (data) => dispatch => {

    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'referral', data)
        
            .then( (response => dispatch({
                type: ADD_BANK_DETAILS,
                payload: response.data
            })))
            .then( response => { 
                if(response.payload.type == 'error'){
                    warning(response.payload.message)
                } else {
                    success(response.payload.message)
                }           
                processing(dispatch, false)
            })
            .catch( error => {
                warning( "Somthing Wrong!")
                processing(dispatch, false)
            })
    });
}

/**
 * find recent rents
 * 
 * @param {*} NA 
 * @return {response} redux store
*/
export const findRecentRents = () => dispatch => {

    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'recent/rent')
        
            .then( (response => dispatch({
                type: RECENT_RENT,
                payload: response.data
            })))
            .catch( error => {
                warning( "Somthing Wrong!")
                processing(dispatch, false)
            })
    });
}

/**
 * get folio property ledger list by property IDs
 * 
 * @param {int} ownerID
 * @return {response} redux store
 */
 export const getFolioLedgersList = (data) => dispatch => {

    processing(dispatch, true)

    Promise.get(sanctum_url).then(response => {
        
        Promise.post(APP_URL+'folio/ledgers', {data: data})
        
            .then( (response => dispatch({
                type: FOLIO_LEDGERS,
                payload: response.data
            })))

            .then( (data => dispatch({
                type: LOADING_COMPONENT,
                payload: false
            }))
        );
    });
}

/**
 * generate Condition Report
 * 
 * @param {int} ownerId 
 * @return {response} redux store
*/
export const generateConditionReport = (data) => dispatch => {

    processing(dispatch, true)
    Promise.get(constants?.MEDIAURLDEV + 'pub/get_condition_report_pdf/'+data?.id)
    
    .then( (response) => {
        processing(dispatch, false)
    })
    .catch( error => { 
        processing(dispatch, false)
        warning( ""+error)
    })
}

/**
 * check user details
 * 
 * @param {array} data 
 * @return {response} redux store
*/
export const checkUserDetails = (data, _this) => dispatch => {

    Promise.post(APP_URL+'auth/details', data)
    
    .then( (response => 
        
        {
            dispatch({
                type: CHECK_SLA_SIGNED,
                payload: response.data?.user_detail?.is_sla_signed
            })
            localStorage.setItem("isSlaSigned", response.data?.user_detail?.is_sla_signed)  
        }
    ))
}
/* get property type
 * 
 * @param null
 * @return {response} redux store
 */
export const getPropertyType = () => dispatch => {

    processing(dispatch, true)

    Promise.get(APP_URL+'property/type')
    
        .then( (response => dispatch({
            type: GET_PROPERTY_TYPE,
            payload: response.data
        })))
        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        }))
    );
}

/* get property location
 * 
 * @param null
 * @return {response} redux store
 */
export const getPropertLocation = ($type, $parentId) => dispatch => {

    processing(dispatch, true)

    Promise.get(APP_URL+'property/location/'+$type+'/'+$parentId)
    
        .then( (response => dispatch({
            type: GET_PROPERTY_LOCATION,
            payload: response.data
        })))
        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        }))
    );
}


/* get property location
 * 
 * @param null
 * @return {response} redux store
 */
export const getStates = (id) => dispatch => {

    processing(dispatch, true)

    Promise.get(APP_URL+'property/states/'+id)
    
        .then( (response => dispatch({
            type: STATE_CITY,
            payload: response.data
        })))
        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        }))
    );
}

/**
 * get folio property ledger list by property IDs
 * 
 * @param {int} ownerID
 * @return {response} redux store
*/
export const savePropertyInformation = (data, fileFormData, uploadUrl) => dispatch => {

    processing(dispatch, true)

    Promise.get(sanctum_url).then(  _ => {
        
        Promise.post(APP_URL+'property/save', data)
        
        .then(  response => { 
            console.log(response, 'response');
            if(response?.data?.error == 'error'){
                warning(response?.data?.message)
            } else {
                success(response?.data?.message)
                uploadPropertyImages(fileFormData, response?.data?.id, dispatch, uploadUrl)
            }           
        })
        .catch( error => {
            warning( "Somthing Wrong!")
            processing(dispatch, false)
        })
    });
}

const uploadPropertyImages = (fileFormData, id, dispatch, uploadUrl) => {

    processing(dispatch, true)
    fileFormData.append( 'property_id', id);

    Promise.post(uploadUrl, fileFormData)
    .then( response => { 
        
        if(response?.status == 'false'){
            warning(response?.message)
        } else {
            window.location.reload()
        }           
        processing(dispatch, false)
    })

    .then( (data => dispatch({
        type: LOADING_COMPONENT,
        payload: false
    })))

    .catch( error => {
        processing(dispatch, false)
    })
}

/* get property utility
 * 
 * @param null
 * @return {response} redux store
 */
export const getUtility = () => dispatch => {

    processing(dispatch, true)

    Promise.get(APP_URL+'property/utility/')
    
        .then( (response => dispatch({
            type: PROPERTY_UTILITY,
            payload: response.data
        })))
        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        }))
    );
}

/**
 * get property media by property ID
 * 
 * @param {int} id 
 * @return {response} redux store
 */
 export const findPropertyMedia = (id) => dispatch => {

    processing(dispatch, true)

    Promise.get(APP_URL+'property/media/'+id)
    
        .then( (response => dispatch({
            type: PROPERTY_MEDIA,
            payload: response.data
        })))
        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        }))
    );
}

/**
 * get folio property ledger list by property IDs
 * 
 * @param {int} ownerID
 * @return {response} redux store
 */
export const updatePropertyInformation = (fileFormData, uploadUrl) => dispatch => {

    processing(dispatch, true)

    Promise.post(uploadUrl, fileFormData)
    .then( response => {           
        updatePropertyDetails(response, fileFormData, dispatch)
    })

    .then( (data => dispatch({
        type: LOADING_COMPONENT,
        payload: false
    })))

    .catch( error => {
        processing(dispatch, false)
    })
}

/**
 * property edit save
 * 
 * @param {form} fileFormData
 * @return {response} redux store
 */
 export const updatePropertyDetails = (response, fileFormData, dispatch) => {

    processing(dispatch, true)

    fileFormData.append('mediaId', response?.data?.response?.mediaIds)

    Promise.post(APP_URL+'property/update', fileFormData)
    .then( response => { 
        
        console.log(response, 'response');

        if(response?.data?.error == true){
            warning(response?.data?.message)
        } else {
            success(response?.data?.message)
            setTimeout(function(){ window.location.reload(); }, 2000);
        }           
        processing(dispatch, false)
    })

    .then( (data => dispatch({
        type: LOADING_COMPONENT,
        payload: false
    })))

    .catch( error => {
        processing(dispatch, false)
    })
}

/**
 * warning message display to user
 * @param {string} msg 
 */
 export const warningMsg = (msg) => {
    return warning(msg)
}

/**
 * get property details by customer ID
 * 
 * @param {int} ownerId 
 * @return {response} redux store
 */
 export const findPropertyList = (ownerId) => async(dispatch) => {

    processing(dispatch, true)

    await Promise.get(APP_URL+'list/property/'+ownerId)
    
        .then( (response => dispatch({
            type: GET_PROPERTY,
            payload: response.data
        })))
        .then( (data => dispatch({
            type: LOADING_COMPONENT,
            payload: false
        }))
    );
}
/**
 * get customer notification
 * 
 * @param {int} ownerId 
 * @return {response} redux store
 */
 export const getNotifications = (authUser, _this) => dispatch => {

    Promise.get(APP_URL+'notification/list/'+authUser?.customer_id)
    
        .then( (response => dispatch({
            type: USER_NOTIFICATION,
            payload: response.data
        })))
        .then( _ => {
            _this.setViewedMsg()
        }
    )
}

/**
 * get customer notification
 * 
 * @param {int} ownerId 
 * @return {response} redux store
 */
 export const setNotificationsViewed = (authUser, props, _this, message_id) => dispatch => {

    let form  = new FormData();
    form.append('message_id', message_id)

    Promise.post(APP_URL+'notification/viewed', form)
    
        .then( (_ => props.getNotifications(authUser, _this) ))

        .catch( error => {
            processing(dispatch, false)
        });
}

/**
 * get customer pending SLA
 * 
 * @param {int} ownerId 
 * @return {response} redux store
 */
 export const getCustomerPendingSla = (authUser, _this) => dispatch => {

    let form  = new FormData();
    form.append('customer_id', authUser?.customer_id)

    Promise.post(APP_URL+'customer/sla', form)
    
    .then( response => dispatch({
        type: PENDING_SLA,
        payload: response.data
    }))
    .then( data => {
        _this.isSlaSigned(data)
    })
    .catch( error => {
        processing(dispatch, false)
    });
}

/**
 * get customer pending SLA
 * 
 * @param {int} ownerId 
 * @return {response} redux store
 */
 export const getCustomerSignedSla = (authUser, _this) => dispatch => {

    let form  = new FormData();
    form.append('customer_id', authUser?.customer_id)

    Promise.post(APP_URL+'customer/signedSla', form)
    
    .then( response => dispatch({
        type: SIGNED_SLA,
        payload: response.data
    }))
    .then( data => {
        _this.isSlaSigned(data)
    })
    .catch( error => {
        processing(dispatch, false)
    });
}

/**
 * Copy Selected bank account to all
 * 
 * @param {array} data
 * @param {int} property_id
 * @param {object} props
 *  
 * @return {response} redux store
*/
export const copyToAll = (data, property_id, props) => dispatch => {
    processing(dispatch, true)
    Promise.get(sanctum_url).then( _ => {
        Promise.post(APP_URL+'copy-account-to-all', data)
        
            .then( (response => dispatch({
                type: SELECT_BANK,
                payload: response.data
            })))
            .then( async response => {  console.log(response, 'response');
                if(response.payload.type == 'error'){
                    warning(response.payload.message)
                } else {
                    await props.getProfile(property_id, 'country', props?.proData?.profile?.customer_id)
                    success(response.payload.message)
                }           
                processing(dispatch, false)
            })
            .catch( error => {
                warning( "Somthing Wrong!")
                processing(dispatch, false)
            })
    });
}

