import StepWizard from "react-step-wizard";
import React, {useEffect, useRef, useState} from "react";
import BuildingTimeSetup from "./BuildingTimeSetup";
import DateTimeSlot from "./DateTimeSlot";
import Note from "./Note";
import moment from "moment/moment";
// Import constants used in the component
import constants from "../../../../../../../component/constants";
import { useSelector } from "react-redux";

const BookingWizard = (props) => {
    // Get helper function from Redux store
    const { helper } = useSelector((state) => ({
        helper: state.dataPack.helper,
    }));

    // Access application URL from constants
    const { APP_URL } = constants;

    // States for handling building metrics data
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Ref to manage fetch request aborts
    const abortController = useRef(null);

    const [state, setState] = useState({
        form: {
            buildingId: null,
            timeZone: null,
            bookingDate: null,
            bookingStartTime: null,
            note: null,
        },
        errors: {},
    });

    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        setState({
            ...state,
            form,
        });
    };

    const submitBooking = async () => {
        // Abort any previous fetch request
        abortController.current?.abort();
        abortController.current = new AbortController();

        // Set loading state
        setIsLoading(true);

        const bookingsEndpoint = APP_URL + `buildings/${state.form.buildingId}/bookings`;
        const bookingData = {
            customer_id: props.customer.customer_id,
            time_zone: state.form.timeZone,
            booking_start: state.form.bookingDate + ' ' + state.form.bookingStartTime,
            note: state.form.note,
        }

        try {
            // Fetch buildings data from API
            const response = await fetch(bookingsEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bookingData),
                signal: abortController.current?.signal,
            });
            const result = await response.json();

            // Check if the API response was successful
            if (response.ok && result.status === 'success') {
                helper.notify(
                    'Success',
                    result.message,
                    'success'
                );
                props.hideBookingModal();
            } else {
                // If the response is not OK, handle error case
                setError(result.message);
                helper.notify(
                    'Error',
                    result.message,
                    'danger'
                );
            }
        } catch (e) {
            if (e.name === 'AbortError') {
                helper.notify(
                    'Error',
                    'Building Meeting Booking Aborted',
                    'danger'
                );
                return;
            }

            // Set error state if fetch fails
            setError(e);
        } finally {
            // Reset loading state
            setIsLoading(false);
        }
    }

    return (
        <StepWizard>
            <BuildingTimeSetup buildings={props.buildings} update={updateForm} />
            <DateTimeSlot form={state.form} update={updateForm} />
            <Note update={updateForm} submit={submitBooking} />
        </StepWizard>
    );
}

export default BookingWizard;
