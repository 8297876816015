import React from 'react';
import { Provider } from 'react-redux';

import PrivateRoute from './privateRoute'
import PublicRoute from './publicRoute'

/** redux store*/
import store from './../redux/store'

/** global react ruter dom component */
import { BrowserRouter, Switch, Route } from 'react-router-dom'

/** login component */
import Login from './../blocks/auth'

/** Forgor Password component */
import ForgorPassword from './../blocks/auth/forgetPassword'

/** change Password component */
import ChangePassword from './../blocks/auth/changePassword'

/** profile component */
import Profile from './../blocks/profile'

/** Tenancy view page index */
import Tenant from './../blocks/sections/tenent'

/** building view page index */
import Building from './../blocks/sections/building'

/** multi residential page index */
import MultiResidential, { BuildingFinancialDetails, BuildingOperationalDetails, BuildingOccupancyDetails } from "./../blocks/sections/multi-residential"

/** folio view page index */
import FolioView from './../blocks/sections/folioView'

/** folio snap page index */
import FolioSnap from './../blocks/sections/folioSnap'

/** For Lease view page index */
import ForLease from './../blocks/sections/forlease'

/** proeprty add and edit/update */
import Property from './../blocks/sections/property'

/** proeprty update and edit/update */
import updateProperty from './../blocks/sections/property/edit'

/** building view page index */
import Bills from './../blocks/sections/tenent/bills'

/** resources view page index */
import Resources from './../../modules/blocks/sections/resource'

/** Rewards view page index */
import Rewards from './../../modules/blocks/sections/rewards'

/** owner list */
import OwnerList from './../../modules/blocks/sections/owner'

import 'bootstrap/dist/css/bootstrap.min.css';

/** custom styles */
import './../../styles/custom.css'

/** tablet styles */
import './../../styles/tablet.css'

/** sla signed */
import signSla from './../blocks/sections/sla'


/**
 * all routes
 *  
 * @param {*} NA
 * 
 * @return {obejct} route
 */

const App = () => (
    <Provider store = { store } >
        <BrowserRouter>
            <Switch>
                <PublicRoute path="/" component={Login} exact={true} />
                <PublicRoute path="/login" component={Login} exact={true} />
                <PublicRoute path="/forget-password" component={ForgorPassword} exact={true} />
                <PrivateRoute path="/profile" component={Profile} exact={true} />
                <PrivateRoute path="/profile/:id" component={Profile} exact={true} />
                <PrivateRoute path="/tenancy" component={Tenant} exact={true} />
                <PrivateRoute path="/building" component={Building} exact={true} />
                <PrivateRoute path="/multi-residential" component={MultiResidential} exact={true} />
                <PrivateRoute path="/multi-residential/:buildingId/financial" component={BuildingFinancialDetails} exact={true} />
                <PrivateRoute path="/multi-residential/:buildingId/operational" component={BuildingOperationalDetails} exact={true} />
                <PrivateRoute path="/multi-residential/:buildingId/occupancy" component={BuildingOccupancyDetails} exact={true} />
                <PrivateRoute path="/tenancy_bills_docs" component={Bills} exact={true} />
                <PrivateRoute path="/tenancy/:id" component={Tenant} exact={true} view="tenancy" />
                <PrivateRoute path="/building/:id" component={Building} exact={true} />
                <PrivateRoute path="/tenancy_bills_docs/:id" component={Bills} exact={true} />
                <PrivateRoute path="/resources" component={Resources} exact={true} />
                <PrivateRoute path="/for-lease" component={ForLease} exact={true} />
                
                <PrivateRoute path="/for-lease/:id" component={ForLease} exact={true} />
                <PrivateRoute path="/folio-view" component={FolioView} exact={true} />
                <PublicRoute path="/change-password/:id" component={ChangePassword} exact={true} />
                <PrivateRoute path="/folio-snap" component={FolioSnap} exact={true} />
                <PrivateRoute path="/add-property" component={Property} exact={true} />
                <PrivateRoute path="/edit-property/:id" component={updateProperty} exact={true} />
                <PrivateRoute path="/owner/list" component={OwnerList} exact={true}/>
                <PrivateRoute path="/folio-snap/recentPayment" component={FolioSnap} exact={true} />
                <PrivateRoute path="/sign/sla" component={signSla} exact={true} />
                <PrivateRoute path="/rewards" component={Rewards} exact={true} />
            </Switch>
        </BrowserRouter>
    </Provider >
);
  
export default App;